import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { faUpload, faHeart } from '@fortawesome/pro-light-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-more-sheet',
  templateUrl: './more-sheet.component.html',
  styleUrls: ['./more-sheet.component.scss']
})
export class MoreSheetComponent implements OnInit{
  faHeartSolid = faHeart;
  faUpload = faUpload;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<MoreSheetComponent>,
    private router: Router
  ) {}

  ngOnInit(): void { }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  navigateToLikes(){
    this.bottomSheetRef.dismiss();
    this.router.navigateByUrl(`/catalog/likes`);
  }

  navigateToPublications(){
    this.bottomSheetRef.dismiss();
    this.router.navigateByUrl(`/catalog/publications`);
  }
  
  navigateToMyShare(){
    this.bottomSheetRef.dismiss();
    this.router.navigateByUrl(`/catalog/likes`); // skal linke til en anden side
  }
}
