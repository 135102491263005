<mat-toolbar color="primary" class="bottom-sheet-title">
  <span>{{ 'more-catalog-sheet.title' | translate }}</span>
</mat-toolbar>

<div class="sheet-wrapper">
  <!-- Edit -->

  <div class="flex-wrapper">
    <button type="button" (click)="navigateToLikes()" aria-label=""
      class="color-button">
      <fa-icon [icon]="faHeartSolid" size="lg"></fa-icon>
      {{ 'more-catalog-sheet.favorites' | translate }}
    </button>

    <!-- Delete -->
    <button type="button" (click)="navigateToPublications()" aria-label=""
      class="color-button">
      <fa-icon [icon]="faUpload" size="lg"></fa-icon>
      {{ 'more-catalog-sheet.my-share' | translate }}
    </button>
  </div>
</div>